import React from 'react';
import PropTypes from 'prop-types';
import Prismic from '@prismicio/client';

const Container = ({ slices }) => {
  const CODE_SNIPPET = 'code_snippet';

  const handleSliceTypeRender = (slice, index) => {
    let content;
    if (slice.sliceType === CODE_SNIPPET && !!slice.nonRepeat) {
      content = <div dangerouslySetInnerHTML={{ __html: slice.nonRepeat.code.blocks[0].text }} />
    }
    return <div key={index}>{content}</div>;
  };

  return (
    <div>
      {!!slices && !!slices.length && slices.map((slice, n) => handleSliceTypeRender(slice, n))}
    </div>
  );
}

export default Container;

Container.propTypes = {
  slices: PropTypes.array.isRequired,
}
