import React from 'react';
import ReactDOM from 'react-dom';
import Container from 'components/cms/container';
import 'styles/cms/kapow_home.scss';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('home-container');
  const props = JSON.parse(node.getAttribute('data-props'));
  ReactDOM.render(<Container {...props} />, node);
});


